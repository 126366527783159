/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { Space, Typography } from "antd";
import DOMPurify from "dompurify";
import { enquireScreen } from "enquire-js";

import PrivacyDataSource from "./data.source";
import "./index.less";
const { Title, Paragraph, Text } = Typography;

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== "undefined" ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const { title, subTitle, supportContent, lastUpdated, contents } =
      PrivacyDataSource;
    const renderChildren = () => {
      return contents.map((item, index) => {
        const { title, desc, subContents } = item;
        const titleId = title.replace(/\s+/g, "-").toLowerCase().toString();
        return title ? (
          <div key={index.toString()} id={titleId}>
            <Space direction="vertical" size={8}>
              <Title level={2}>{title}</Title>
              {subContents && subContents.length > 0 ? (
                subContents.map((item, index) => (
                  <div key={index.toString()}>
                    <Space direction="vertical" size={4}>
                      <Title level={4}>{item.title}</Title>
                      <div
                        className="paragraph"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.desc),
                        }}
                      />
                    </Space>
                  </div>
                ))
              ) : (
                <div
                  className="paragraph"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.desc),
                  }}
                />
              )}
            </Space>
          </div>
        ) : null;
      });
    };

    return (
      <div
        className="privacy-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        <Space direction="vertical" size={8}>
          <Title level={1}>{title}</Title>
          <Paragraph>{supportContent}</Paragraph>
          <Space size={16} align="baseline">
            {subTitle ? <Title level={2}>{subTitle}</Title> : null}
            <Text>{lastUpdated}</Text>
          </Space>
        </Space>
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && renderChildren()}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
