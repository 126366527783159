export default {
  title: "NIK Mall Privacy Policy",
  subTitle: "",
  supportContent:
    "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.",
  lastUpdated: "Last updated January 17, 2022",
  contents: [
    {
      title: "Interpretation and Definitions",
      subContents: [
        {
          title: "Interpretation",
          desc: "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.",
        },
        {
          title: "Definitions",
          desc: `For the purposes of this Privacy Policy:
          <br/>
          <strong>Account</strong> means a unique account created for You to access our Service or parts of 
          our Service.
          <br/>
          <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control 
          with a party, where "control" means ownership of 50% or more of the shares, equity 
          interest or other securities entitled to vote for election of directors or other 
          managing authority.
          <br/>
          <strong>Application</strong> means the software program provided by the Company downloaded by 
          You on any electronic device, named NIK Mall
          <br/>
          <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this 
          Agreement) refers to NIK Mall.
          <br/>
          <strong>Country</strong> refers to: Cambodia
          <br/>
          <strong>Device</strong> means any device that can access the Service such as a computer, a 
          cellphone or a digital tablet.
          <br/>
          <strong>Personal Data</strong> is any information that relates to an identified or identifiable 
          individual.
          <br/>
          <strong>Service</strong> refers to the Application.
          <br/>
          <strong>Service Provider</strong> means any natural or legal person who processes the data on 
          behalf of the Company. It refers to third-party companies or individuals employed 
          by the Company to facilitate the Service, to provide the Service on behalf of the 
          Company, to perform services related to the Service or to assist the Company in 
          analyzing how the Service is used.
          <br/>
          <strong>You</strong> means the individual accessing or using the Service, or the company, or other 
          legal entity on behalf of which such individual is accessing or using the Service, as 
          applicable.
          `,
        },
      ],
    },
    {
      title: "Collecting and Using Your Personal Data",
      subContents: [
        {
          title: "Types of Data Collected",
        },
        {
          title: "Personal Data",
          desc: `While using Our Service, We may ask You to provide Us with certain personally identifiable 
          information that can be used to contact or identify You. Personally identifiable information 
          may include, but is not limited to:
            <ul style="margin-left: 20px">
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address</li>
            </ul>`,
        },
        {
          title: "Information Collected while Using the Application",
          desc: `While using Our Application, in order to provide features of Our Application, We may 
            collect, with Your prior permission:
              <ul style="margin-left: 20px">
                  <li>Pictures and other information from your Device's camera and photo library</li>
              </ul>
            We use this information to provide features of Our Service, to improve and customize Our 
            Service. The information may be uploaded to the Company's servers and/or a Service 
            Provider's server or it may be simply stored on Your device.
            <br/>
            You can enable or disable access to this information at any time, through Your Device 
            settings.`,
        },
        {
          title: "Use of Your Personal Data",
          desc: `The Company may use Personal Data for the following purposes:
            <br/>
            <strong>To provide and maintain our Service</strong>, including to monitor the usage of our 
            Service.
            <br/>
            <strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The 
            Personal Data You provide can give You access to different functionalities of the 
            Service that are available to You as a registered user.
            <br/>
            <strong>For the performance of a contract:</strong> the development, compliance and undertaking 
            of the purchase contract for the products, items or services You have purchased or 
            of any other contract with Us through the Service.
            <br/>
            <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent 
            forms of electronic communication, such as a mobile application's push notifications 
            regarding updates or informative communications related to the functionalities, 
            products or contracted services, including the security updates, when necessary or 
            reasonable for their implementation.
            <br/>
            <strong>To provide You</strong> with news, special offers and general information about other 
            goods, services and events which we offer that are similar to those that you have 
            already purchased or enquired about unless You have opted not to receive such 
            information.
            <br/>
            <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
            <br/>
            <strong>For business transfers:</strong> We may use Your information to evaluate or conduct a 
            merger, divestiture, restructuring, reorganization, dissolution, or other sale or 
            transfer of some or all of Our assets, whether as a going concern or as part of 
            bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us 
            about our Service users is among the assets transferred.
            <br/>
            <strong>For other purposes:</strong> We may use Your information for other purposes, such as data 
            analysis, identifying usage trends, determining the effectiveness of our promotional 
            campaigns and to evaluate and improve our Service, products, services, marketing 
            and your experience.
            <br/>
            <br/>
            We may share Your personal information in the following situations:
            <ul style="margin-left: 20px">
                <li><strong>With Service Providers:</strong> We may share Your personal information with Service 
                Providers to monitor and analyze the use of our Service, to contact You.</li>
                <li><strong>For business transfers:</strong> We may share or transfer Your personal information in 
                connection with, or during negotiations of, any merger, sale of Company assets, 
                financing, or acquisition of all or a portion of Our business to another company.</li>
                <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we 
                will require those affiliates to honor this Privacy Policy. Affiliates include Our parent 
                company and any other subsidiaries, joint venture partners or other companies that We 
                control or that are under common control with Us.</li>
                <li><strong>With business partners:</strong> We may share Your information with Our business partners 
                to offer You certain products, services or promotions.</li>
                <li><strong>With other users:</strong> when You share personal information or otherwise interact in the 
                public areas with other users, such information may be viewed by all users and may be 
                publicly distributed outside.</li>
                <li><strong>With Your consent:</strong> We may disclose Your personal information for any other purpose 
                with Your consent.</li>
            </ul>`,
        },
        {
          title: "Retention of Your Personal Dat",
          desc: `The Company will retain Your Personal Data only for as long as is necessary for the 
          purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the 
          extent necessary to comply with our legal obligations (for example, if we are required to 
          retain your data to comply with applicable laws), resolve disputes, and enforce our legal 
          agreements and policies.`,
        },
        {
          title: "Transfer of Your Personal Data",
          desc: `Your information, including Personal Data, is processed at the Company's operating offices 
            and in any other places where the parties involved in the processing are located. It means 
            that this information may be transferred to — and maintained on — computers located 
            outside of Your state, province, country or other governmental jurisdiction where the data 
            protection laws may differ than those from Your jurisdiction.
            <br>
            <br>
            Your consent to this Privacy Policy followed by Your submission of such information 
            represents Your agreement to that transfer.
            <br>
            <br>
            The Company will take all steps reasonably necessary to ensure that Your data is treated 
            securely and in accordance with this Privacy Policy and no transfer of Your Personal Data 
            will take place to an organization or a country unless there are adequate controls in place 
            including the security of Your data and other personal information.
          `,
        },
        {
          title: "Disclosure of Your Personal Data",
        },
        {
          title: "Business Transactions",
          desc: `If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be 
            transferred. We will provide notice before Your Personal Data is transferred and becomes 
            subject to a different Privacy Policy.`,
        },
        {
          title: "Law enforcement",
          desc: `Under certain circumstances, the Company may be required to disclose Your Personal Data 
          if required to do so by law or in response to valid requests by public authorities (e.g. a court 
          or a government agency).`,
        },
        {
          title: "Other legal requirements",
          desc: `The Company may disclose Your Personal Data in the good faith belief that such action is 
          necessary to:
            <ul style="margin-left: 20px">
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
            </ul>
          `,
        },
        {
          title: "Security of Your Personal Data",
          desc: `The security of Your Personal Data is important to Us, but remember that no method of 
            transmission over the Internet, or method of electronic storage is 100% secure. While We 
            strive to use commercially acceptable means to protect Your Personal Data, We cannot 
            guarantee its absolute security.`,
        },
      ],
    },
    {
      title: "Changes to this Privacy Policy",
      desc: `We may update Our Privacy Policy from time to time. We will notify You of any changes by 
        posting the new Privacy Policy on this page.
        </br>
        </br>
        We will let You know via email and/or a prominent notice on Our Service, prior to the 
        change becoming effective and update the "Last updated" date at the top of this Privacy 
        Policy.
        </br>
        </br>
        You are advised to review this Privacy Policy periodically for any changes. Changes to this 
        Privacy Policy are effective when they are posted on this page.
      `,
    },
    {
      title: "Contact Us",
      desc: `If you have any questions about this Privacy Policy, You can contact us:
      </br>
      <strong>By email:</strong> nikmall8988@gmail.com
      </br>
      <strong>By visiting this page on our website:</strong> https://support.sularkak.biz/
      </br>
      <strong>By phone number:</strong> 077898863`,
    },
  ],
};
