import React from "react";
import { Typography } from "antd";
import { AndroidFilled, AppleFilled } from "@ant-design/icons/lib/icons";

export const Nav30DataSource = {
  wrapper: { className: "header3 home-page-wrapper kygvqj8avej-editor_css" },
  page: { className: "home-page" },
  logo: {
    className: "header3-logo",
    children: require("../assets/nik-mall-logo.png"),
  },
};
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5 kygoe9had4-editor_css" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper kygoe605bn9-editor_css",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Everyday Shopping</p>
          </span>
        ),
        className: "banner5-title kygodx8mk5u-editor_css",
      },
      {
        name: "explain",
        className: "banner5-explain",
        children: (
          <span>
            <p>With millions of products just</p>
            <p>on your mobile phone</p>
          </span>
        ),
      },
      {
        name: "content",
        className: "banner5-content",
        children: (
          <span>
            <p>
              Smart Search with just 1 click, Track your order at ease, &amp;
              Easy refund
            </p>
          </span>
        ),
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "https://play.google.com/store/apps",
          className: "banner5-button",
          type: "primary",
          icon: <AndroidFilled />,
          children: (
            <span>
              <p>For Android</p>
            </span>
          ),
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "https://www.apple.com/app-store/",
          className: "banner5-button",
          type: "primary",
          icon: <AppleFilled />,
          children: (
            <span>
              <p>For iPhone/iPad</p>
            </span>
          ),
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
    ],
  },
  image: {
    className: "banner5-image kygo0ykktmm-editor_css",
    children: require("../assets/banner-img-1.png"),
  },
};
export const Feature00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <p>We Are Awesome</p>
            </span>
          </span>
        ),
        className: "feature-title",
      },
      {
        name: "content",
        className: "feature-content",
        children: (
          <span>
            <p>Hello, Are you looking for some help?</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: (
                <img src={require("../assets/call-icon.png")} alt="call-icon" />
              ),
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Call Us</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>077 898 863</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: (
                <img
                  src={require("../assets/email-icon.png")}
                  alt="email-icon"
                />
              ),
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Email Us</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <p>nikmall8988@gmail.com</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children: (
                <img src={require("../assets/chat-icon.png")} alt="chat-icon" />
              ),
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Chat Us</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>Facebook Messenger Live Chat</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Footer20DataSource = {
  wrapper: {
    className: "home-page-wrapper footer2-wrapper kygvq9r3i1-editor_css",
  },
  OverPack: { className: "home-page footer2", playScale: 0.05 },
  copyright: {
    className: "copyright",
    children: [
      {
        name: "group",
        children: (
          <span>
            <p>Copyright © 2021 NIK Mall</p>
          </span>
        ),
        className: "copyright-group",
      },
      {
        name: "group",
        children: (
          <span>
            <Typography.Link href="/privacy" target="_blank">
              Privacy
            </Typography.Link>
          </span>
        ),
        className: "copyright-links",
      },
      {
        name: "image2",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/fgGmQUfiUfSBfvsQpfOj.svg",
        className: "copyright-line",
      },
      {
        name: "copyright",
        children: (
          <span>
            <p>Designed &amp; Powered By Ninestacks Studio</p>
          </span>
        ),
        className: "copyright-text",
      },
    ],
  },
  links: {
    className: "links",
    children: [
      {
        name: "facebook",
        href: "https://www.facebook.com/NIK-Mall-108097901693376/",
        target: "_blank",
        rel: "noopener noreferrer",
        className: "links-facebook",
        children: "https://cdn-icons-png.flaticon.com/512/1384/1384005.png",
      },
      {
        name: "whatsapp",
        href: "https://wa.me/85577898863",
        target: "_blank",
        rel: "noopener noreferrer",
        className: "links-whatsapp",
        children: "https://cdn-icons-png.flaticon.com/512/739/739196.png",
      },
      {
        name: "telegram",
        href: "https://t.me/joinchat/xIYo0TBF_yc4MzU1",
        target: "_blank",
        rel: "noopener noreferrer",
        className: "links-telegram",
        children: "https://cdn-icons-png.flaticon.com/512/2111/2111710.png",
      },
    ],
  },
};
