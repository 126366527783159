import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Home/Nav3';
import Footer from './Home/Footer2';
import Home from './Home';
import Privacy from './Privacy';
import './App.less';

import {
  Nav30DataSource,
  Footer20DataSource,
} from './Home/data.source.js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    // Support for mobile resolutions
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    return (
      <div>
        <Header dataSource={Nav30DataSource} isMobile={this.isMobile} />
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/privacy" element={<Privacy/>} />
          </Routes>
        <Footer dataSource={Footer20DataSource} isMobile={this.isMobile} />
      </div>
    );
  }
}

export default App;